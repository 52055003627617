import { render, staticRenderFns } from "./news-detail.vue?vue&type=template&id=4502ffac&scoped=true&"
import script from "./news-detail.vue?vue&type=script&lang=js&"
export * from "./news-detail.vue?vue&type=script&lang=js&"
import style0 from "./news-detail.vue?vue&type=style&index=0&id=4502ffac&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules_front/2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4502ffac",
  null
  
)

export default component.exports