export default {
    baseUrl: 'http://localhost:8080/springbootp297o/',
    indexNav: [
        {
            name: '首页',
            url: '/index/home'
        },
        {
            name: '亲子旅游攻略',
            url: '/index/lvyougonglve'
        },
        {
            name: '亲子旅游套餐',
            url: '/index/lvyoutaocan'
        },
        {
            name: '公告信息',
            url: '/index/news'
        },
        {
            name: '留言反馈',
            url: '/index/messages'
        },
    ]
}
